<template>
  <div class="favorite-properties">
    <div class="list" v-if="properties.length > 0">
      <div v-for="p in properties" :key="p.token">
        <span class="title">{{ p.translations[0].title }}</span>
        <button @click="$emit('addClose', p)">{{ $t("clients.add_close") }}</button>
      </div>
    </div>
    <span v-else>{{ $t("clients.no_favorite_property") }}</span>
    <footer class="flex">
      <button class="colored" @click="$emit('previousTab')">{{ $t("overall.back") }}</button>
      <div class="cta" @click="$emit('nextTab')">{{ $t("clients.close_deal") }}</div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "FavoriteProperties",
  props: ["properties"],
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
});
</script>
